import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../utils/seo"
import richText from "../utils/richText"
import { Embed } from "semantic-ui-react"

const Taller = ({ data }) => {
  const {
    name,
    content,
    image,
    categoria,
    idioma,
    videoUrl,
  } = data.contentfulTallers
  const descripcio = richText(content.json)

  return (
    <Layout
      imageSrc={image.file.url}
      title={name}
      breadcrum={[
        { name: "Inici", link: "/" },
        { name: "Tallers", link: "/tallers" },
      ]}
    >
      <SEO title={name} />
      <div style={{ margin: "30px auto", maxWidth: 1000 }}>
        <div className="row" style={{ margin: 20 }}>
          <div className="column col2">
            <div className="descripcio" style={{ marginBottom: 40 }}>
              {descripcio}
              {videoUrl && (
                <Embed
                  icon="right circle arrow"
                  key={videoUrl}
                  iframe={{
                    allowFullScreen: true,
                    style: {
                      padding: 10,
                    },
                  }}
                  // placeholder="/images/image-16by9.png"
                  url={videoUrl}
                />
              )}
            </div>
          </div>
          <div className="column col2">
            <span style={{
              position: 'absolute',
              margin: '-6px 0 0 15px',
              backgroundColor: 'white',
              padding: '0 6px'
            }}>Fitxa tècnica</span>
            <div className="fitxaTecnica" style={{ border: '1px solid lightgrey', borderRadius: 5, padding: 10 }}>
              <br />
              <div className="categories">
                <h4>Categories</h4>
                <p>{categoria && categoria.map(cat => cat.nom).join(", ")}</p>
              </div>
              <br />
              <div className="idiomes">
                <h4>Idiomes</h4>
                <p>{idioma && idioma.map(i => i.nom).join(", ")}</p>
              </div>
              <br />
              <div className="info">
                <h4>T'interessa?</h4>
                <Link to="/contacte">
                  <div className="tagline-link button button-small menu-contrast-button">
                    Demanar més informació
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Taller
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulTallers(slug: { eq: $slug }) {
      content {
        json
      }
      id
      image {
        file {
          url
        }
      }
      name
      slug
      categoria {
        nom
      }
      idioma {
        nom
      }
      videoUrl
    }
  }
`
